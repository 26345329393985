<div class="content-wrapper">
  <svg xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:cc="http://creativecommons.org/ns#" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd" xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape" width="34.66486mm" height="37.799946mm" viewBox="0 0 34.66486 37.799946" version="1.1" id="svg202" inkscape:version="1.0.1 (3bc2e813f5, 2020-09-07)" sodipodi:docname="drawing.svg">
    <defs id="defs196" />
    <metadata id="metadata199"></metadata>
    <g inkscape:label="Layer 1" inkscape:groupmode="layer" id="layer1" transform="translate(-72.625903,-76.350022)">
        <g id="symbol" transform="matrix(0.26458333,0,0,0.26458333,-90.440296,65.473001)" style="fill:#77a7ff;fill-opacity:1">
            <path class="cls-2" d="M 912.25,389.89 941.76,379 a 63.83,63.83 0 0 0 -3.54,-8 l -27.91,14.46 a 32.71,32.71 0 0 1 1.94,4.43 z" transform="translate(-198.23,-282.89)" id="path159" style="fill:#77a7ff;fill-opacity:1" />
            <path class="cls-2" d="m 908.9,383 26.53,-16.86 a 62.35,62.35 0 0 0 -3.59,-5.1 l -24.79,19.33 c 0.66,0.85 1.27,1.73 1.85,2.63 z" transform="translate(-198.23,-282.89)" id="path161" style="fill:#77a7ff;fill-opacity:1" />
            <path class="cls-2" d="m 913,410.32 30.34,8.23 a 65.09,65.09 0 0 0 2.2,-13.41 l -31.39,-1.84 a 33.75,33.75 0 0 1 -1.15,7.02 z" transform="translate(-198.23,-282.89)" id="path163" style="fill:#77a7ff;fill-opacity:1" />
            <path class="cls-2" d="m 914.16,399.45 31.4,-1.58 A 65.44,65.44 0 0 0 943.62,385 l -30.47,7.81 a 33.48,33.48 0 0 1 1.01,6.64 z" transform="translate(-198.23,-282.89)" id="path165" style="fill:#77a7ff;fill-opacity:1" />
            <path class="cls-2" d="m 908.26,420.53 25.94,17.77 a 65.84,65.84 0 0 0 7.09,-13.51 L 912,413.43 a 33.75,33.75 0 0 1 -3.74,7.1 z" transform="translate(-198.23,-282.89)" id="path167" style="fill:#77a7ff;fill-opacity:1" />
            <path class="cls-2" d="m 899.13,429.6 17.34,26.23 A 65.92,65.92 0 0 0 929.8,444 L 906,423.48 a 34.18,34.18 0 0 1 -6.87,6.12 z" transform="translate(-198.23,-282.89)" id="path169" style="fill:#77a7ff;fill-opacity:1" />
            <path class="cls-2" d="M 880.07,435.41 A 34.13,34.13 0 0 1 878,367.22 V 380 l 14.69,-10.44 23.73,-16.86 -20,-14.94 L 878,324 v 11.76 a 65.57,65.57 0 1 0 31,124.4 L 895.16,431.9 a 34.07,34.07 0 0 1 -15.09,3.51 z" transform="translate(-198.23,-282.89)" id="path171" style="fill:#77a7ff;fill-opacity:1" />
        </g>
    </g>
  </svg>

  <article class="page-content">
    <header class="homepage-banner tools-banner" role="banner">
      <div class="banner-inner">
        <h1 wmAnimate="landing" speed="normal" delay="500ms">UTIL Generator<span>Smart <strong>Code</strong> Platform</span></h1>
      </div>
    </header>

    <main role="main">
      <section class="blue-bg-summary">
        <div class="section-inner">
          <!-- <div class="flex-columns">
            <div> -->
              <h2 once="true" speed="fast" wmAnimate="fadeInUp" aos="0.25">
                Utilities for the development, testing and deployment of integrations for the iPaaS and open-source technology.
              </h2>
              <ul>
                <li>Offers Full RAML Support</li>
                <li>Creates Postman Collections for Unit Testing</li>
              </ul>
            <!-- </div> -->
            <!-- <div>
              <h3>Generate Postman Collections</h3>
              <p>Pre-built collections for testers</p>
              <div class="diagram">
                <span class="box">Schema</span>
                <img src="/assets/images//circleA.svg" class="rorating-circle" alt="">
                <span class="box">Postman</span>
              </div>
            </div> -->
          <!-- </div> -->
        </div>
      </section>

      <section class="">
        <div class="section-inner">
          <h2 once="true" speed="fast" wmAnimate="fadeInUp" aos="0.25" class="no-bottom-margin">
            Comprehensive test cases made easy.<br>Minimal <span class="blue-underline">handover</span>.
          </h2>


          <div class="logos-bar" >
            <ul>
              <li>
                <figure>
                  <div class="img-container">
                    <img src="/assets/images/client-systems/postman-original.png" alt="AWS Logo" class="mouseout-gray">
                    <img src="/assets/images/client-systems/postman-blue.png" alt="AWS Logo" class="mouseout-gray">
                  </div>
                  <figcaption>Automates Postman Collection from a Schema</figcaption>
                </figure>
              </li>
              <li>
                <figure>
                  <div class="img-container">
                    <img src="/assets/images/tools/bridge-color.png" class="mouseout-gray" alt="Azure Logo">
                    <img src="/assets/images/tools/bridge-blue.png" class="mouseout-gray" alt="Azure Logo">
                  </div>
                  <figcaption>Bridges the Gap Between Developers and Testers</figcaption>
                </figure>
              </li>
              <li>
                <figure>
                  <div class="img-container">
                    <img src="/assets/images/tools/data-structures-color.png" class="mouseout-gray" alt="Google Cloud Logo">
                    <img src="/assets/images/tools/data-structures-blue.png" class="mouseout-gray" alt="Google Cloud Logo">
                  </div>
                  <figcaption>Minimalizes Navigating Complex Data Structures</figcaption>
                </figure>
              </li>
              <li>
                <figure>
                  <div class="img-container">
                    <img src="/assets/images/tools/human-error-color.png" class="mouseout-gray" alt="Cloud Native Logo">
                    <img src="/assets/images/tools/human-error-blue.png" class="mouseout-gray" alt="Cloud Native Logo">
                  </div>
                  <figcaption>Eliminates Human Error</figcaption>
                </figure>
              </li>
            </ul>





          </div>
        </div>
      </section>

      <section class="more-bottom-margin">
        <div class="section-inner">

          <div class="transparetn-block-columns">
            <div class="transparent-block flipped-bg  style-2-left" once="true" speed="fast" wmAnimate="fadeInUp" delay="250ms" aos="0.25">
              <div class="transparent-block-text space-between single-block">
                <div class="diagram">
                  <span class="box">Schema</span>
                  <img src="/assets/images//circleA.svg" class="rorating-circle" alt="">
                  <span class="box">Postman</span>
                </div>
                <h2>Generate Postman Collections</h2>
                <p>Pre-built collections to simplify the handover from developers to testers.</p>
                <a role="button" style="margin-left: -.5em;" (click)="Register()" class="text-button lighter narrower no-bg external-link dark-container" rel="noopener noreferrer">Sign up for free</a>
              </div>
            </div>

            <div class="transparent-block   style-1-right" once="true" speed="fast" wmAnimate="fadeInUp" delay="250ms" aos="0.25">
              <div class="transparent-block-text space-evenly">
                <h3><strong>Smart Code</strong> PROVIDES FULL RAML SUPPORT FOR INTEROPERABILITY.</h3>
                <a role="button" style="margin-left: -.5em;" (click)="Register()" class="text-button lighter narrower no-bg external-link dark-container" rel="noopener noreferrer">Sign up for free</a>
              </div>
            </div>

          </div>



          <!-- <div class="transparent-block narrow style-1-left margin-bottom">
            <div>
              <div>
                <h2>Conversion tools</h2>
                <h5>Enabling interoperability between API Specification standards to upgrade, covert and test schemas when working with RAML.</h5>
                <a role="button" style="margin-left: -.5em;" (click)="Register()" class="text-button lighter narrower no-bg external-link dark-container" rel="noopener noreferrer">Sign up for free</a>
              </div>
            </div>
          </div> -->

        </div>
      </section>

      <section class="blue full-width">
        <div class="section-inner">
          <h2>Developing Contract-First APIs</h2>
          <h3>What is a contract?</h3>
          <p>An API Contract is an agreement between two or more parties that outlines how an API is used. WSDL, RAML and OAS (OpenAPI) are widely adopted standards for documenting API contracts.</p>
          <h3>Contract-First vs Contract-Last</h3>
          <p>Contract-First means you design the way your services are going to talk to each other before you implement the services. Contract-Last means you implement the service then you generate the contract.</p>
          <h3>Smart Code uses the Contract-First approach to maximize the following benefits</h3>
          <ul>
            <li>Clear and consistent API design</li>
            <li>Early agreement on requirements</li>
            <li>Development teams can work in parallel</li>
            <li>Helps manage structured API versioning</li>
          </ul>
        </div>
      </section>

    </main>
  </article>
</div>
