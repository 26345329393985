import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { faFile, faTimes } from '@fortawesome/free-solid-svg-icons';
import { CodeExampleData, hPos, sizes, vPos } from '../../shared/code-example/code-example-data';
import { AuthService } from 'projects/nine-gold-lib/src/lib/services/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  imageLarge = false;
  imageLarge2 = false;

  imageLargeJson = false;
  jsonImageJson2 = false;

  final = false;
  done = false;
  descVisible: boolean[] = [false, false, false]

  finalJson = false;
  doneJson = false;

  closeIcon = faTimesCircle;
  fileIcon = faFile;
  @ViewChild('jsonExampleImage') jsonExampleImage: ElementRef;
  @ViewChild('threeImagesContainer') threeImagesContainer: ElementRef;

  @ViewChild('jsonSchema') jsonSchema: ElementRef;
  @ViewChild('jsonImagesContainer') jsonImagesContainer: ElementRef;

  jsonSchemaExmaple: CodeExampleData = {
    mainImage: '/assets/images/2-codes1.png',
    numberOfImages: 2,
    popupImages: [
      {
        imagePath: '/assets/images/JsonSchema_RAML_Input.png',
        heading: 'Input',
        text: 'RAML 1.0 NamedExample',
        vPos: vPos.top,
        hPos: hPos.left,
        size: sizes.half
      },
      {
        imagePath: '/assets/images/JsonSchema_JSON_Output.png',
        heading: 'Output',
        text: 'JSON formatted file',
        vPos: vPos.top,
        hPos: hPos.right,
        size: sizes.half
      }
    ]
  };

  postmanExample: CodeExampleData = {
    mainImage: '/assets/images/postman-double-code.jpg',
    numberOfImages: 2,
    popupImages: [
      {
        imagePath: '/assets/images/postman-input-code.jpg',
        heading: 'Input',
        text: 'SOAP Service WSDL Definition',
        vPos: vPos.top,
        hPos: hPos.left,
        size: sizes.half
      },
      {
        imagePath: '/assets/images/postman-output-code.jpg',
        heading: 'Output',
        text: 'A Postman Collection to test the schema from a REST Client.  For SOAP Services designed to test through a REST wrapper.',
        vPos: vPos.top,
        hPos: hPos.right,
        size: sizes.half
      }
    ]
  }

  oasRamlExample: CodeExampleData = {
    mainImage: '/assets/images/OAS_RAML.png',
    numberOfImages: 2,
    popupImages: [
      {
        imagePath: '/assets/images/OAS_input.png',
        heading: 'Input',
        text: 'OpenAPI Specification in JSON or YAML format',
        vPos: vPos.top,
        hPos: hPos.left,
        size: sizes.half
      },
      {
        imagePath: '/assets/images/RAML_output.png',
        heading: 'Output',
        text: 'RAML 1.0 Specification including main file, examples, data types, examples, securitySchemes and headers',
        vPos: vPos.top,
        hPos: hPos.right,
        size: sizes.half
      }
    ]
  }

  constructor(
    private authService: AuthService,
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
  }


  imagePopupJson():void {
    this.imageLargeJson = true;
    this.jsonSchema.nativeElement.style.top = this.jsonSchema.nativeElement.getBoundingClientRect().top.toString().concat('px');
    this.jsonSchema.nativeElement.style.left = this.jsonSchema.nativeElement.getBoundingClientRect().left.toString().concat('px');
    this.jsonSchema.nativeElement.style.width = this.jsonSchema.nativeElement.getBoundingClientRect().width.toString().concat('px');
    this.jsonSchema.nativeElement.style.height = this.jsonSchema.nativeElement.getBoundingClientRect().height.toString().concat('px');
    this.jsonSchema.nativeElement.style.zIndex = '100000';
    this.jsonSchema.nativeElement.style.position = 'fixed';
    this.jsonImagesContainer.nativeElement.style.height = (0.6 * this.jsonImagesContainer.nativeElement.getBoundingClientRect().width).toString().concat('px');
    setTimeout(() => {
      this.jsonImageJson2 = true;
      // let el1 = this.renderer.sele
      // let el = this.renderer.selectRootElement('.site-container');
      const el = document.querySelector('.site-container');
      this.renderer.setStyle(el, 'position', 'relative');
      this.renderer.setStyle(el, 'z-index', '10000');
      setTimeout(() => {
        this.jsonSchema.nativeElement.style.top = '0px';
        this.jsonSchema.nativeElement.style.left = '0px';
        this.jsonSchema.nativeElement.style.width = '100%';
        this.jsonSchema.nativeElement.style.height = '100%';
        this.jsonImagesContainer.nativeElement.style.height = '100vmin';
        setTimeout(() => {
          this.finalJson = true;
          const mixImage = document.querySelector('.mix-image');
          setTimeout(() => {
            this.doneJson = true;
          }, 1000);
        }, 800);
      },20);
    },20);
  }

  imagePopup():void {
    this.imageLarge = true;
    this.jsonExampleImage.nativeElement.style.top = this.jsonExampleImage.nativeElement.getBoundingClientRect().top.toString().concat('px');
    this.jsonExampleImage.nativeElement.style.left = this.jsonExampleImage.nativeElement.getBoundingClientRect().left.toString().concat('px');
    this.jsonExampleImage.nativeElement.style.width = this.jsonExampleImage.nativeElement.getBoundingClientRect().width.toString().concat('px');
    this.jsonExampleImage.nativeElement.style.height = this.jsonExampleImage.nativeElement.getBoundingClientRect().height.toString().concat('px');
    this.jsonExampleImage.nativeElement.style.zIndex = '100000';
    this.jsonExampleImage.nativeElement.style.position = 'fixed';
    this.threeImagesContainer.nativeElement.style.height = (0.6 * this.threeImagesContainer.nativeElement.getBoundingClientRect().width).toString().concat('px');

    setTimeout(() => {
      this.imageLarge2 = true;
      // let el1 = this.renderer.sele
      // let el = this.renderer.selectRootElement('.site-container');
      const el = document.querySelector('.site-container');
      this.renderer.setStyle(el, 'position', 'relative');
      this.renderer.setStyle(el, 'z-index', '10000');
      setTimeout(() => {
        this.jsonExampleImage.nativeElement.style.top = '0px';
        this.jsonExampleImage.nativeElement.style.left = '0px';
        this.jsonExampleImage.nativeElement.style.width = '100%';
        this.jsonExampleImage.nativeElement.style.height = '100%';
        this.threeImagesContainer.nativeElement.style.height = '100vmin';
        setTimeout(() => {
          this.final = true;
          const mixImage = document.querySelector('.mix-image');
          setTimeout(() => {
            this.done = true;
          }, 1000);
        }, 800);
      }, 20);
    }, 20);


  }

  Register() {
    this.authService.getRegistrationLink().then(link => window.location.href = link);
  }

  toggleDesc(index: number): void {
    if(this.descVisible[index]) {
      this.descVisible[index] = false;
    } else {
      this.descVisible.fill(false);
      // this.descVisible.forEach((el,i) => {
      //   this.descVisible[i] = false;
      // });
      this.descVisible[index] = true;
    }
    // this.descVisible[index] = !this.descVisible[index];
  }

  closePopup():void {
    this.jsonExampleImage.nativeElement.style.opacity = 0;
    setTimeout(() => {
      const el = document.querySelector('.site-container');
      this.renderer.removeStyle(el,'position');
      this.renderer.removeStyle(el,'z-index');
      this.final = false;
      this.imageLarge2 = false;
      this.imageLarge = false;
      this.done = false;
      this.jsonExampleImage.nativeElement.style.width = null;
      this.jsonExampleImage.nativeElement.style.height = null;
      this.jsonExampleImage.nativeElement.style.top = null;
      this.jsonExampleImage.nativeElement.style.left = null;
      this.jsonExampleImage.nativeElement.style.zIndex = null;
      this.jsonExampleImage.nativeElement.style.position = null;
      this.threeImagesContainer.nativeElement.style.height = null;
      this.jsonExampleImage.nativeElement.style.opacity = null;
    }, 500);
  }

}
