export interface CodeExampleData {
    mainImage: string,
    numberOfImages: number,
    popupImages: exampleAndImage[]
}

export interface exampleAndImage {
    imagePath: string,
    heading: string,
    text: string,
    size: sizes,
    vPos: vPos,
    hPos: hPos,
    class?: string
}

export enum sizes {
    half = 'half',
    quoter = 'quoter'
}

export enum vPos {
    top = 'top',
    bottom = 'bottom'
}

export enum hPos {
    left = 'left',
    right = 'right'
}