import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { SigninRedirectCallbackComponent } from 'projects/nine-gold-lib/src/lib/common/auth-pages/signin-redirect-callback.component';
import { SignoutRedirectCallbackComponent } from 'projects/nine-gold-lib/src/lib/common/auth-pages/signout-redirect-callback.component';
import { CanDeactivateGuard } from 'projects/nine-gold-lib/src/lib/services/guards/can-deactivate.guard';
import { SupportRequestComponent } from './pages/support-request/support-request.component';

const routes: Routes = [
  { path: 'app',  loadChildren: async () => (await import('./application/tools-app.module').then(m => m.ToolsAppModule))  },
  { path: 'signin-callback', component: SigninRedirectCallbackComponent },
  { path: 'signout-callback', component: SignoutRedirectCallbackComponent },
  { path: 'supportrequest', component: SupportRequestComponent, outlet: 'sidePopup',canDeactivate: [CanDeactivateGuard]},
  { path: 'home', component: HomeComponent },
  { path: '', component: HomeComponent },
  { path: '**', redirectTo: '/' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
