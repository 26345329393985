import { Component, ElementRef, HostListener, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { fadeInAnimation } from 'projects/nine-gold-lib/src/lib/animations/fade-in.animation';
import { MenuItemType, NgNavigation, NgNavigationApps } from 'projects/nine-gold-lib/src/lib/shared/ng-navigation/ng-navigation';
import { ApiUser, IUser } from 'projects/nine-gold-lib/src/lib/models/user';
import { AuthService } from 'projects/nine-gold-lib/src/lib/services/auth.service';
import { UserInterfaceService } from 'projects/nine-gold-lib/src/lib/services/user-interface.service';
import { UserService } from 'projects/nine-gold-lib/src/lib/services/user.service';
import { NotificationService } from 'projects/nine-gold-lib/src/public-api';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { FormResultMessageFormat } from 'projects/nine-gold-lib/src/lib/shared/notification/notification';
import { ToolsSettingsService } from './services/tools-settings.service';
import { Title } from '@angular/platform-browser';
import { AppSettingsService } from 'projects/nine-gold-lib/src/lib/services/app-settings.service';
import { ProductService } from 'projects/nine-gold-lib/src/lib/services/product.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [fadeInAnimation]
})
export class AppComponent {
  title = 'UTIL Generator';
  scrolledDown = false;
  scrolling = false;
  offsetY: number = 0;
  scrollingUp = false;
  noHeader = false;
  scrollableHeader = true;
  userPages = false;
  appLayout = false;
  hideFooter = false;

  headerNavItems: NgNavigation[];
  productNavItems: NgNavigation[];
  solutionsNavItems: NgNavigation[];
  resourcesNavItems: NgNavigation[];
  footerNavItems: NgNavigation[];
  appNavItems: NgNavigationApps[];

  @ViewChild('outerAppContainer') outerAppContainer: ElementRef;

  constructor(
    public uiService: UserInterfaceService,
    private renderer: Renderer2,
    private userService: UserService,
    private router: Router,
    private titleService: Title,
    private authService: AuthService,
    private activeRoute: ActivatedRoute,
    private notifications: NotificationService,
    private appSettings: AppSettingsService,
    private productService: ProductService,
    protected gaService: GoogleAnalyticsService
  ) {
    this.headerNavItems = appSettings.getTopNav();
    this.productNavItems = appSettings.getProductLinks();
    this.solutionsNavItems = appSettings.getSolutionsLinks();
    this.resourcesNavItems = appSettings.getResourcesLinks();
    this.footerNavItems = appSettings.getFooterNav();
    this.appNavItems = appSettings.getAppLinks();

    let appPagesTimeout;

    router.events.subscribe((val) => {

      if(val instanceof NavigationStart) {
        this.hideFooter = true;
        document.body.style.height = document.body.clientHeight + "px";
        document.body.classList.add('navigation-transition');
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        let spinnerContainer = document.body.getElementsByClassName('app-loading-spinner-container')[0];
        if(spinnerContainer) {
          spinnerContainer.classList.remove('active');
          setTimeout(() => {
            spinnerContainer.remove();
          }, 1000);
        }
      }

      if(val instanceof NavigationEnd) {

        this.gaService.pageView(val.url, this.title)

        setTimeout(() => {
          this.hideFooter = false;
          document.body.style.height = "";
          document.body.classList.remove('navigation-transition');
        }, 1000);

        if(val.url.startsWith('/login')) {
          this.noHeader = true;
        } else {
          this.noHeader = false;
        }
        if(val.url.startsWith('/user')) {
          this.userPages = true;
          this.scrollableHeader = false;
          this.appLayout = false;
        } else if (val.url.startsWith('/app')) {
          this.scrollableHeader = false;
          this.appLayout = true;
          this.userPages = false;
        } else if (val.url === '/') {
          this.scrollableHeader = true;
          this.userPages = false;
          this.appLayout = false;
        } else {
          this.scrollableHeader = false;
          this.appLayout = false;
          this.userPages = false;

          if(val.url.startsWith('/app') || activeRoute.root.firstChild.snapshot.data['layout'] === 'user'
            || activeRoute.root.firstChild.snapshot.data['layout'] === 'app') {
            this.userPages = true;
            this.appLayout = true;
            this.uiService.sideMenu = true;
            if(appPagesTimeout) {
              clearTimeout(appPagesTimeout);
            }
            // this.uiService.sideMenuWide = (window.innerWidth > 800);
          } else {
            appPagesTimeout = setTimeout(() => {
              this.userPages = false;
              // this.appLayout = false;
            }, 500);


            this.uiService.sideMenu = false;
            this.uiService.sideMenuWide = (window.innerWidth > 800);
          }
        }


      }
    });
  }

  ngOnInit(): void {
    this.uiService.showDemo = false;
    this.uiService.showQuote = false;
    this.titleService.setTitle(this.title);
    let token: string;
    this.userService.clearUser();
    token = this.userService.getToken();

    this.productService.productsUpdated.subscribe(products => {
      this.appNavItems = this.appSettings.getAppLinks();
    });
    if(token) {
      this.authService.getUserInfo(token).subscribe({
        next: result => {
          // console.log(result);
          let user = new ApiUser;
          user = this.userService.map(user, result);
          this.userService.setUser(user);
          this.notifications.success('User Logged In', `User: ${user.username} was successfully logged in.`,FormResultMessageFormat.popup, null, null);
          // this.router.navigate([this.returnUrl]);

          // this.user = plainToClass(IUser, result, {
          //   //excludeExtraneousValues: true,
          // });
        },
        error: err => console.log(err)
      })
    }
    // new SmoothScroll(document,120,12);
  }

  @HostListener('window:scroll',['$event']) onScroll(event) {
    const verticalOffset = window.pageYOffset
          || document.documentElement.scrollTop
          || document.body.scrollTop || 0;
    this.scrollingUp = verticalOffset < this.offsetY;
    this.scrolling = verticalOffset > 50
    // this.scrolledDown = verticalOffset > 100;
    this.scrolledDown = verticalOffset + this.outerAppContainer.nativeElement.clientHeight + 100 > this.outerAppContainer.nativeElement.scrollHeight;
    this.offsetY = verticalOffset;
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

  onPageSwitch(event): void {
    // console.log(event);
  }
  onDeactivateMain(event):void {
    // this.uiService.setUserDropdownStatus(false, true);
  }

  onActivate(event):void {
    // console.log(event);
    this.renderer.addClass(document.body, 'modal-open');
  }
  onDeactivate(event):void {
    // console.log(event);
    this.uiService.setUserDropdownStatus(false);
    this.renderer.removeClass(document.body, 'modal-open');
  }
}
