import { Injectable } from '@angular/core';
import { AppSettingsService } from 'projects/nine-gold-lib/src/lib/services/app-settings.service';
import { columnFilterTypes } from 'projects/nine-gold-lib/src/lib/shared/listComponents/ng-list/ng-list-data';
// import { columnFilterTypes } from 'projects/nine-gold-lib/src/lib/shared/ng-list/ng-list-data';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ToolsSettingsService extends AppSettingsService {

  env = environment;

  constructor() {
    super();

    this.productCode = "tools";

    this.baseSetting = this.env;

    this.setNavigations(
      this.baseSetting.projectUrls.platform,
      this.baseSetting.projectUrls.generator,
      this.baseSetting.projectUrls.tools
      );

    this.UpdateLocalLinks(this.productLinks, this.baseSetting.baseUrl);
    this.UpdateLocalLinks(this.appsList, this.baseSetting.baseUrl);
    this.UpdateLocalLinks(this.footerLinks, this.baseSetting.baseUrl);
    this.UpdateLocalLinks(this.infoNavLinks,this.baseSetting.baseUrl);
    this.topNavLinks = this.RemoveLinks(this.topNavLinks, this.baseSetting.baseUrl);

    this.donwnloadFilters = {
      filterId: '',
      filterName: 'Generator Type',
      filterType: columnFilterTypes.options,
      filterPlaceholder: "All",
      optionSelected: null,
      filterOptions: [
        {
          value: 'JSON_EXAMPLE',
          text: 'JSON Example'
        },
        {
          value: 'TOOLS_RAML10',
          text: 'RAML 1.0 Converter'
        }
      ]
    };

    this.defaulDownloadParams = {
      numberOfPages: 5,
      currentPage: 1,
      itemsPerPage: 10,
      filters: [],
      sorting: {
        field: "generated",
        order: "d"
      }
    }
  }
  
}
