<lib-notification></lib-notification>
<div class="content-container"
    #outerAppContainer
    [class.app-layout]="appLayout"
    [class.hide-header]="noHeader"
    [class.static-header]="!scrollableHeader"
    [class.scrolled]="scrolledDown"
    [class.scrolling]="scrolling"
    [class.scrollingUp]="scrollingUp" >

    <lib-header
      [@fadeInAnimation]
      [appView]="appLayout"
      [hideUserDropdown]="userPages"
      [productsList]="productNavItems"
      [solutionsList]="solutionsNavItems"
      [resourcesList]="resourcesNavItems"
      [appsList]="appNavItems"
      [linksList]="headerNavItems"></lib-header>

    <div class="site-container" [@fadeInAnimation]="o.isActivated ? o.activatedRoute : ''">
        <router-outlet (activate)="onPageSwitch($event)" name="primary" id="scrollContainer" #o="outlet"></router-outlet>
    </div>
</div>

<div class="lib-footer-container" [hidden]="hideFooter">
  <lib-footer *ngIf="!userPages && !noHeader && !appLayout" [linksList]="footerNavItems"></lib-footer>
</div>

<div class="side-popup-container">
  <router-outlet
    name="sidePopup"
    (activate)='onActivate($event)'
  (deactivate)='onDeactivate($event)'></router-outlet>
</div>
