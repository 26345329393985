import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AnimateModule } from '@wizdm/animate';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { SigninRedirectCallbackComponent } from 'projects/nine-gold-lib/src/lib/common/auth-pages/signin-redirect-callback.component';
import { SignoutRedirectCallbackComponent } from 'projects/nine-gold-lib/src/lib/common/auth-pages/signout-redirect-callback.component';
import { NineGoldLibModule } from 'projects/nine-gold-lib/src/lib/nine-gold-lib.module';
import { ApiInterceptorService } from 'projects/nine-gold-lib/src/lib/services/api-interceptor.service';
import { AppSettingsService } from 'projects/nine-gold-lib/src/lib/services/app-settings.service';
import { AppconfigModule } from 'projects/nine-gold-lib/src/lib/services/appconfig/appconfig.module';
import { NotificationModule } from 'projects/nine-gold-lib/src/lib/shared/notification/notification.module';
import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { SupportRequestComponent } from './pages/support-request/support-request.component';
import { ToolsSettingsService } from './services/tools-settings.service';
import { CodeExampleComponent } from './shared/code-example/code-example.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SigninRedirectCallbackComponent,
    SignoutRedirectCallbackComponent,
    CodeExampleComponent,
    SupportRequestComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NineGoldLibModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AppconfigModule,
    NotificationModule,
    AnimateModule,
    FontAwesomeModule,
    NgxGoogleAnalyticsModule.forRoot(environment.ga),
    NgxGoogleAnalyticsRouterModule
  ],
  providers: [{ provide: AppSettingsService, useClass: ToolsSettingsService},
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptorService, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
